import store from '@/store'
import { camperStudent, sessionSemester } from '@/helpers/instanceNamingConvention'
import instanceModuleUsing from '@/helpers/instanceModuleUsing'
import { permissionSubjects } from '@/libs/acl/constants'

export default [
  {
    header: 'Pages',
  },
  // {
  //   title: 'Children',
  //   route: 'admin-campers',
  //   icon: 'UserIcon',
  // },
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'admin-dashboard',
    action: 'read',
    resource: permissionSubjects.User,
    requiresSchoolAndProgram: false,
  },
  {
    title: 'Program',
    icon: 'GridIcon',
    isOpen: true,
    requiresSchoolAndProgram: true,
    children: [
      {
        title: `${camperStudent}s`,
        route: 'admin-students',
        action: 'read',
        resource: permissionSubjects.Child,
      },
      {
        title: 'Parents',
        route: 'admin-parents',
        action: 'read',
        resource: permissionSubjects.Parent,
      },
      {
        title: 'Staff',
        route: 'admin-staff',
        action: 'read',
        resource: permissionSubjects.Staff,
      },
      {
        title: `${camperStudent} Applications`,
        route: {
          name: 'admin-applications',
          params: { type: camperStudent },
        },
        action: 'read',
        resource: permissionSubjects.ChildApplication,
      },
      {
        title: 'Staff Applications',
        route: {
          name: 'admin-applications',
          params: { type: 'Staff' },
        },
        action: 'read',
        resource: permissionSubjects.StaffApplication,
      },
      {
        title: 'Groups',
        route: 'admin-groups',
        action: 'read',
        resource: permissionSubjects.Group,
      },
      {
        title: 'Group Types',
        route: 'admin-group-types',
        action: 'read',
        resource: permissionSubjects.GroupType,
      },
      {
        title: 'Tasks',
        route: 'admin-tasks',
        action: 'read',
        resource: permissionSubjects.Tasks,
      },
      {
        title: 'Payment Contract Users',
        route: 'admin-program-users-contracts',
        action: 'read',
        resource: permissionSubjects.PaymentContract,
      },
      {
        title: 'Program Transactions',
        route: 'admin-program-transactions',
        action: 'read',
        resource: permissionSubjects.Transaction,
      },
      {
        title: 'Tag view',
        route: 'admin-tag-view',
        action: 'read',
        resource: permissionSubjects.Tags,
      },
      {
        title: 'Files',
        route: 'admin-file-folders',
        action: 'update',
        resource: permissionSubjects.GeneralProgramSettings,
      },
    ],
  },
  {
    title: 'Program Setup',
    icon: 'SlidersIcon',
    requiresSchoolAndProgram: true,
    children: [
      {
        title: 'General',
        route: {
          name: 'admin-program-edit',
          params: { id: store.getters['verticalMenu/getDefaultProgram'] },
          query: { tab_index: 0 },
        },
        action: 'update',
        resource: permissionSubjects.GeneralProgramSettings,
      },
      {
        title: `${sessionSemester}s`,
        route: {
          name: 'admin-program-edit',
          params: { id: store.getters['verticalMenu/getDefaultProgram'] },
          query: { tab_index: 1 },
        },
        action: 'update',
        resource: permissionSubjects.GeneralProgramSettings,
      },
      {
        title: 'Communications',
        route: {
          name: 'admin-program-edit',
          params: { id: store.getters['verticalMenu/getDefaultProgram'] },
          query: { tab_index: 2 },
        },
        action: 'update',
        resource: permissionSubjects.GeneralProgramSettings,
      },
      {
        title: `Registration (${camperStudent}s)`,
        route: {
          name: 'admin-program-edit',
          params: { id: store.getters['verticalMenu/getDefaultProgram'] },
          query: { tab_index: 3 },
        },
        action: 'update',
        resource: permissionSubjects.RegistrationChildProgramSettings,
      },
      {
        title: 'Registration (Staff)',
        route: {
          name: 'admin-program-edit',
          params: { id: store.getters['verticalMenu/getDefaultProgram'] },
          query: { tab_index: 4 },
        },
        action: 'update',
        resource: permissionSubjects.RegistrationStaffProgramSettings,
      },
      {
        title: 'Tasks',
        route: {
          name: 'admin-program-edit',
          params: { id: store.getters['verticalMenu/getDefaultProgram'] },
          query: { tab_index: 5 },
        },
        action: 'update',
        resource: permissionSubjects.ProgramTasks,
      },
      {
        title: 'Fees',
        route: {
          name: 'admin-program-edit',
          params: { id: store.getters['verticalMenu/getDefaultProgram'] },
          query: { tab_index: 6 },
        },
        action: 'update',
        resource: permissionSubjects.ProgramFees,
      },
      {
        title: 'Wallets',
        route: {
          name: 'admin-program-edit',
          params: { id: store.getters['verticalMenu/getDefaultProgram'] },
          query: { tab_index: 7 },
        },
        action: 'update',
        resource: permissionSubjects.ProgramWallets,
      },
      {
        title: 'Tags',
        route: {
          name: 'admin-program-edit',
          params: { id: store.getters['verticalMenu/getDefaultProgram'] },
          query: { tab_index: 8 },
        },
        action: 'update',
        resource: permissionSubjects.ProgramTags,
      },
      {
        title: 'Family Settings',
        route: {
          name: 'admin-program-edit',
          params: { id: store.getters['verticalMenu/getDefaultProgram'] },
          query: { tab_index: 9 },
        },
        action: 'update',
        resource: permissionSubjects.GeneralProgramSettings,
        isHidden: !instanceModuleUsing('Family'),
      },
    ],
  },
  {
    title: 'Reports',
    icon: 'BarChart2Icon',
    requiresSchoolAndProgram: true,
    children: [
      {
        title: 'Reports List',
        route: 'admin-reports-folders',
        action: 'read',
        resource: permissionSubjects.Report,
      },
      {
        title: 'Tuition Cards',
        route: 'admin-fafsa-reports',
        action: 'read',
        resource: permissionSubjects.FinancialReport,
      },
    ],
  },
  {
    title: 'Billing',
    icon: 'DollarSignIcon',
    requiresSchoolAndProgram: true,
    children: [
      {
        title: 'All Transactions',
        route: 'admin-transactions',
        action: 'read',
        resource: permissionSubjects.Transaction,
      },
      {
        title: 'Payment Contracts Setup',
        route: 'admin-payment-contracts',
        action: 'read',
        resource: permissionSubjects.PaymentContract,
      },
    ],
  },
  {
    title: 'Families',
    icon: 'UsersIcon',
    requiresSchoolAndProgram: true,
    route: 'admin-families',
    action: 'read',
    resource: permissionSubjects.User,
    isHidden: !instanceModuleUsing('Family'),
  },
  {
    title: 'Communicator',
    route: 'admin-mass-communication',
    icon: 'MailIcon',
    requiresSchoolAndProgram: true,
    action: 'send',
    resource: [permissionSubjects.BulkSMSCommunication, permissionSubjects.BulkEmailCommunication],
  },
  {
    title: 'Academics',
    route: 'admin-academics',
    icon: 'BookOpenIcon',
    requiresSchoolAndProgram: true,
    action: 'read',
    resource: permissionSubjects.Academics,
  },
  {
    title: 'Forms',
    icon: 'FileTextIcon',
    requiresSchoolAndProgram: true,
    children: [
      {
        title: 'All Forms',
        route: 'admin-form-folders',
        icon: 'FileTextIcon',
        requiresSchoolAndProgram: true,
        action: 'read',
        resource: permissionSubjects.Form,
      },
      {
        title: 'Family Forms',
        route: 'admin-family-form-folders',
        icon: 'FileTextIcon',
        requiresSchoolAndProgram: true,
        action: 'read',
        resource: permissionSubjects.Form,
        isHidden: !instanceModuleUsing('Family'),
      },
    ],
  },
  {
    title: 'Tag scanner',
    route: 'admin-tag-scanner',
    icon: 'TargetIcon',
    requiresSchoolAndProgram: true,
    action: 'scan',
    resource: permissionSubjects.Tags,
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    requiresSchoolAndProgram: true,
    tagVariant: 'light-danger',
    children: [
      // {
      //   title: 'Report Types',
      //   route: 'admin-reports-view-list',
      // },
      {
        title: 'Verified Emails',
        route: 'admin-verified-emails',
        action: 'read',
        resource: permissionSubjects.EmailTemplate,
      },
      {
        title: 'Email Templates',
        route: 'admin-email-templates-folders',
        action: 'read',
        resource: permissionSubjects.EmailTemplate,
      },
      {
        title: 'Sms Templates',
        route: 'admin-sms-templates',
        action: 'read',
        resource: permissionSubjects.SMSTemplate,
      },
      {
        title: 'Letter Templates',
        route: 'admin-letter-templates',
        action: 'read',
        resource: permissionSubjects.EmailTemplate,
      },
      {
        title: 'Group List Templates',
        route: 'admin-group-list-templates',
        action: 'read',
        resource: permissionSubjects.EmailTemplate,
      },
      {
        title: 'Tuition Cards Templates',
        route: 'admin-tuition-card-templates',
        action: 'read',
        resource: permissionSubjects.EmailTemplate,
      },
      {
        title: 'Scheduled Emails',
        route: 'admin-scheduled-emails',
        action: 'read',
        resource: permissionSubjects.ScheduledEmails,
      },
      {
        title: 'Communication',
        route: 'admin-settings-communication',
        action: 'read',
        resource: permissionSubjects.EmailOnEvent,
      },
      {
        title: 'Email Audit',
        route: 'admin-email-audit',
        action: 'read',
        resource: permissionSubjects.EmailAudit,
      },
      {
        title: 'SMS Audit',
        route: 'admin-sms-audit',
        action: 'read',
        resource: permissionSubjects.SMSAudit,
      },
      {
        title: 'Users',
        route: 'admin-users',
        action: 'read',
        resource: permissionSubjects.User,
      },
      {
        title: 'Dashboard',
        route: 'admin-settings-dashboard',
        action: 'read',
        resource: permissionSubjects.User,
      },
      {
        title: 'Grade Mapping',
        route: 'admin-grade-mapping',
        action: 'update',
        resource: permissionSubjects.GeneralProgramSettings,
      },
      {
        title: 'Programs Settings',
        route: 'admin-programs-settings',
        action: 'update',
        resource: permissionSubjects.GeneralProgramSettings,
      },
      {
        title: 'Pages',
        route: 'admin-cms-pages',
        action: 'read',
        resource: permissionSubjects.Page,
      },
    ],
  },

]
